<div class="container">
    <img [src]="'../../../../assets/img/landings/hallowen/' + path + '/' + translate.currentLang + '/banner.webp'"
        alt="halloween">
    <div class="wrapper">
        <h1>{{'HALLOWEN_1' | translate}} <br> {{'HALLOWEN_2' | translate}} </h1>
       
      
        <div style="text-align: left;">
                <span>{{'HALLOWEN_3' | translate}}</span>
                <br><br>
        <h2>{{'HALLOWEN_4' | translate}}</h2>
        <span>{{'HALLOWEN_5' | translate}}</span>
        <br><br>
        <h2>{{'HALLOWEN_6' | translate}}</h2>
        <span [innerHTML]="'HALLOWEN_8' | translate"></span>
        <br><br>
        <h2>{{'HALLOWEN_9' | translate}}</h2>
        <span [innerHTML]="'HALLOWEN_10' | translate"></span>
        <br>
        <span [innerHTML]="'HALLOWEN_11' | translate"></span>
        <br><br><br>
</div>
        <span class="width-us">{{'HALLOWEN_7' | translate}}</span>
        <div class="product-container">
            <a target="blank" href="https://www.amazon.com/b?node=122309382011&ref_=pe_129012270_1076147500_undefined0301BT_nas&discounts-widget=%2522%257B%255C%2522state%255C%2522%253A%257B%255C%2522refinementFilters%255C%2522%253A%257B%257D%257D%252C%255C%2522version%255C%2522%253A1%257D%2522"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/1.webp'"
                     alt="amazon halloween"></a>
            <a target="blank" href="https://www.mackenzie-childs.com/seasonal/halloween-seasonal/?trk_msg=J2SV20N7J6KKN0M615C7HVMCS4&trk_contact=O3Q4O8RRQQ8AOPGODKGE0SC6B4&trk_sid=PMAHHH1263CEA06BJ0O6RG163K&trk_link=CKFC70P9GL2KF1V2BCE9O6MPNG&utm_source=Listrak&utm_medium=Email&utm_term=FINELY+CRAFTED+HALLOWEEN+FAVORITES+%7c+THE+HALLOWEEN+SHOP&utm_campaign=It%e2%80%99s+going%2c+going+...+(almost)+gone!&prefn1=instock&prefv1=true"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/2.webp'"
                     alt="mackenzie-childs halloween"></a>
            <a target="blank" href="https://us.shein.com/magicsavings-picks.html?onelink=4/412kqkm2puj1&requestId=530983121764810958&em_dc=us&ecrm_s=aFY4QnBpeHUyRHIzMG8yVuZVGyo3NbmtaVkiFTxo75Ue7nhO5CFck19bTwwCjvBBU46Crzq3Ir/hVYlIykm/JYIOAHH9l7nBMpgf4KMtfbny2CFQFvWbitMec1VJWFlB2z5DeN5FGHmYAaM8V3jSxA%3D%3D&em_ti=M710284386700853&url_from=msp_178_1028438_20240926_H01_us_30Open240926en_OR_us_en_de_M1IJ28UL28"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/3.webp'"
                     alt="shein halloween"></a>
            <a target="blank" href="https://www.fashionnova.com/collections/halloween"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/4.webp'"
                     alt="fashionnova halloween"></a>
            <a target="blank" href=" https://www.stanley1913.com/collections/halloween?utm_source=Klaviyo&utm_medium=email&utm_campaign=2024-09-23_WebGlowTeaser&utm_id=Campaign%20Type&_kx=ZC37EECkjC4hRCxh_DWMDBG-z1Vvl7ghuf6lIG5vV_-aVydBUZBzac0RgE-r_7S2.R5Qx7X"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/5.webp'"
                     alt="stanley halloween"></a>
            <a target="blank" href="https://www.gap.com/browse/girls/halloween-shop?cid=1108134&tid=gpemmain1&mi_u=385978848&EV=GPUS12PMSTPCPATHTFTD5JOB5536175_NLPSPRPDM08252024&DI=385978848&CD=GPNCNL_GPR&cp_cid=83e2a969aab357bb"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/6.webp'"
                     alt="gap hallowene"></a>
            <a target="blank" href="https://www.potterybarn.com/shop/holiday-decor/holiday-decor-halloween/?cm_ite=hero&cp_cid=83e2a969aab357bb&sfmc_subid=67454678&cm_ven=Promo&cm_cat=CAT&cm_pla=2024-09-05_PB_ST_FF1_Seasonal&cm_em=02%3A3680DC283C5B3B622AF7B15D9E66BB312D87E43BEAEBE0816AF6B6DB63B7F9EC&dtm_em=4457332cf02f26e15e97c2d7757bb843&om_mid=SF32380"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/7.webp'"
                     alt="pottery barn hallowene"></a>
            <a target="blank" href="https://www.williams-sonoma.com/shop/holidays/halloween/?cm_re=spotlight-_-default-_-holidays_halloween"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/8.webp'"
                     alt="williams-sonoma halloween"></a>
            <a target="blank" href="https://www.westelm.com/shop/holidays/halloween/?cm_type=gnav&originsc=holidays&tabnav=non-mobiletabnav"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/9.webp'"
                     alt="west elm halloween"></a>
            <a target="blank" href="https://www.walmart.com/cp/halloween/9516159?athAsset=eyJhdGhjcGlkIjoiNDBmNDIwN2YtNjdhNC00NzQ3LTlmMzItMTIzNGI0YjVmODVhIn0=&athena=true"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/10.webp'"
                     alt="walmart halloween"></a>
            <a target="blank" href="https://www.orientaltrading.com/halloween/new-a1-90000+1237+16-1.fltr?pg=8&BP=PS544&ms=search&source=google&cm_mmc=GooglePLA-_-20542385894-_-OTCPMAX-_-&cm_mmca1=OTC%2BPLAs&cm_mmca2=GooglePLAs&gad_source=1&gclid=Cj0KCQjwmOm3BhC8ARIsAOSbapXUsuhb6epcKhHcaBRHQuLLKGMXzNesEtKd5eGSIb6PWyuYmIVSW0IaAtKKEALw_wcB"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/11.webp'"
                     alt="oriantal trading halloween"></a>
            <a target="blank" href="https://www.target.com/c/halloween-party-supplies-holiday-shop/-/N-4y6xj"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/12.webp'"
                     alt="target halloween"></a>
            <a target="blank" href="https://thepartydarling.com/collections/halloween-party-supplies?srsltid=AfmBOoru-IT-NV7i1rL1FbHuojoa2-uDNky2kLJIf6ifmRBQcRUE8MLB"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/13.webp'"
                     alt="the party darling halloween"></a>
            <a target="blank" href="https://www.prettyday.com/collections/halloween-party-supplies?srsltid=AfmBOoqDDXqJovaQmXSnJVpIFirGeVLm60LfeYNNdwkZ65U-m83vaydN"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/14.webp'"
                     alt="prettyday halloween"></a>
            <a target="blank" href="https://partyamerica.com/"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/15.webp'"
                     alt="party america halloween"></a>
            <a target="blank" href="https://www.spirithalloween.com/category/party-supplies/pc/1005/1025.uts"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/16.webp'"
                     alt="spirit halloween"></a>
            <a target="blank" href="https://merimeri.com/collections/halloween?srsltid=AfmBOoqTfs_BB1fdIXawF4tXYqszGI55uG7W5F4c9aGuDvV8RRqfXk4w"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/17.webp'"
                     alt="meri meri halloween"></a>
            <a target="blank" href="https://www.stanley1913.com/collections/halloween?utm_source=Klaviyo&utm_medium=email&utm_campaign=2024-09-23_WebGlowTeaser&utm_id=Campaign%20Type&_kx=ZC37EECkjC4hRCxh_DWMDBG-z1Vvl7ghuf6lIG5vV_-aVydBUZBzac0RgE-r_7S2.R5Qx7X"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/18.webp'"
                     alt="horchow halloween"></a>
            <a target="blank" href="https://www.halloweencostumes.com/"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/19.webp'"
                     alt="halloweencostumes.com halloween"></a>
            <a target="blank" href="https://www.halloweenexpress.com/"> <img
                    [src]="'../../../../assets/img/landings/hallowen/' + path +'/' + translate.currentLang +'/20.webp'"
                     alt="halloween express halloween"></a>

        </div>
    </div>
</div>