import { Component, OnInit } from '@angular/core';
import { WindowReferenceService } from '../../../core/services/window-reference.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hallowen',
  templateUrl: './hallowen.component.html',
  styleUrl: './hallowen.component.scss',
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class HallowenComponent  implements OnInit{

  isMobile = false
  path = 'web'

  constructor(
    private _windowRef: WindowReferenceService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.onResize()
  }

  onResize(event?: any) {
    let w = this._windowRef?.nativeWindow?.innerWidth;
    if (w < 768) {
      this.path = 'mobile'
    } else {
      this.path = 'web'
    }
  }

}
